/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

// Slider Setup //
function sliderSetup() {
    $('.slider-container .slick-initialized').slick('unslick');

    var $sliderContainer = $('.slider-container');

    $sliderContainer.each(function () {
        var $container = $(this),
            $slider = $container.find('.slider'),
            $controls = $container.find('.slider-controls'),
            $dots = $container.find('.slider-dots'),
            centerMode = false,
            dots = false,
            infinite = false,
            fade = false,
            auto = false;

        // Dots //
        if ($container.hasClass('dots')) {
            dots = true;
        }

        // Infinite //
        if ($container.hasClass('infinite')) {
            infinite = true;
        }

        // Fade //
        if ($container.hasClass('fade')) {
            fade = true;
        }

        // Auto //
        if ($container.hasClass('auto')) {
            auto = true;
        }

        // Center Mode //
        if ($container.hasClass('center-mode')) {
            centerMode = true;
        }

        $slider.not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: dots,
            infinite: infinite,
            fade: fade,
            autoplay: auto,
            autoplaySpeed: 4000,
            appendDots: $dots,
            appendArrows: $controls,
            centerMode: centerMode,
            prevArrow:
                '<a class="slick-prev"><i class="fa fa-angle-left"></i></a>',
            nextArrow:
                '<a class="slick-next"><i class="fa fa-angle-right"></i></a>'
        });
    });
}
//setup slider -- function end

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var html = $('html');

        $('.nav-mobile-icon--wrapper').click(function() {
          html.removeClass('loading').toggleClass('nav-open');
        });

        $('.mobile-nav-primary-close--wrapper').click(function() {
            html.removeClass('nav-open');
        });

        $('body')
            .on('click', '.profile--link', function (e) {
                e.preventDefault();
                icon = $(this).find('span.icon');
                link = $(this).attr('data-profile-id');
                profileWrapper = $(this).closest('.profile--wrapper');
                profileContent = profileWrapper.find('div#' + link);
                if ( profileContent.hasClass('collapse') ) {
                    profileContent.removeClass('collapse');
                    profileContent.addClass('show');
                    icon.addClass('d-none');
                }
                else {
                    profileContent.addClass('collapse');
                    profileContent.removeClass('show');
                    icon.removeClass('d-none');
                }
            });

        
        sliderSetup();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        var frontPageSlider = $('.front-page--slider');
        var frontPageSliderNext = $('.btn-next');
        var frontPageSliderPrev = $('.btn-prev');

        frontPageSlider.owlCarousel({
           items: 1,
           loop: true
        });

        frontPageSliderNext.on('click', function() {
            frontPageSlider.trigger('next.owl.carousel');
          console.log('clicked next');
        });

        frontPageSliderPrev.on('click', function() {
            console.log('clicked prev');
            frontPageSlider.trigger('prev.owl.carousel');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_architecture_project': {
      init: function() {
        // $('.architecture--gallery').owlCarousel({
        //     items: 1,
        //     loop: true,
        //     dots: true
        // });
      }
    },
    'page_template_template_projects': {
      init: function() {
        $('.project-wrapper--toggle').on('click', function(e) {
          e.preventDefault();

          var toggleItem = $(this).attr('data-toggle-wrapper');

          $('.project-details--wrapper').each(function() {
            if($(this).hasClass('active')) {
              $(this).removeClass('active');
            }
          });

          $('.' + toggleItem).addClass('active');
        });

        $('.project-details--close').on('click', function(e) {
          e.preventDefault();

          var toggleItem = $(this).attr('data-toggle-wrapper');

            $('.' + toggleItem).removeClass('active');
        });
      }
    },
    'page_template_template_contact': {
    init: function() {
        (function($) {

            /*
            *  new_map
            *
            *  This function will render a Google Map onto the selected jQuery element
            *
            *  @type	function
            *  @date	8/11/2013
            *  @since	4.3.0
            *
            *  @param	$el (jQuery element)
            *  @return	n/a
            */

            function new_map( $el ) {

                // var
                var $markers = $el.find('.marker');


                // vars
                var args = {
                    zoom		: 16,
                    center		: new google.maps.LatLng(0, 0),
                    mapTypeId	: google.maps.MapTypeId.ROADMAP,
                    styles      : [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#181818"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#1b1b1b"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2c2c2c"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#8a8a8a"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#373737"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#3c3c3c"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#4e4e4e"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#000000"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#3d3d3d"
                                }
                            ]
                        }
                    ]
                };


                // create map
                var map = new google.maps.Map( $el[0], args);


                // add a markers reference
                map.markers = [];


                // add markers
                $markers.each(function(){

                    add_marker( $(this), map );

                });


                // center map
                center_map( map );


                // return
                return map;

            }

            /*
            *  add_marker
            *
            *  This function will add a marker to the selected Google Map
            *
            *  @type	function
            *  @date	8/11/2013
            *  @since	4.3.0
            *
            *  @param	$marker (jQuery element)
            *  @param	map (Google Map object)
            *  @return	n/a
            */

            function add_marker( $marker, map ) {

                // var
                var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

                // create marker
                var marker = new google.maps.Marker({
                    position	: latlng,
                    map			: map
                });

                // add to array
                map.markers.push( marker );

                // if marker contains HTML, add it to an infoWindow
                if( $marker.html() )
                {
                    // create info window
                    var infowindow = new google.maps.InfoWindow({
                        content		: $marker.html()
                    });

                    // show info window when marker is clicked
                    google.maps.event.addListener(marker, 'click', function() {

                        infowindow.open( map, marker );

                    });
                }

            }

            /*
            *  center_map
            *
            *  This function will center the map, showing all markers attached to this map
            *
            *  @type	function
            *  @date	8/11/2013
            *  @since	4.3.0
            *
            *  @param	map (Google Map object)
            *  @return	n/a
            */

            function center_map( map ) {

                // vars
                var bounds = new google.maps.LatLngBounds();

                // loop through all markers and create bounds
                $.each( map.markers, function( i, marker ){

                    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                    bounds.extend( latlng );

                });

                // only 1 marker?
                if( map.markers.length == 1 )
                {
                    // set center of map
                    map.setCenter( bounds.getCenter() );
                    map.setZoom( 16 );
                }
                else
                {
                    // fit to bounds
                    map.fitBounds( bounds );
                }

            }

            /*
            *  document ready
            *
            *  This function will render each map when the document is ready (page has loaded)
            *
            *  @type	function
            *  @date	8/11/2013
            *  @since	5.0.0
            *
            *  @param	n/a
            *  @return	n/a
            */
// global var
            var map = null;

            $(document).ready(function(){

                $('.acf-map').each(function(){

                    // create map
                    map = new_map( $(this) );

                });

            });

        })(jQuery);
    }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
